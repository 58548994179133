<template>
    <v-row dense>
      <v-col>
        <div class="white--text mt-2">Treasury Tower Lantai 56 Unit C-1,District 8 @SCBD, 
          Kel. Senayan, Kec. Kebayoran Baru, Kota Adm. Jakarta Selatan, Provinsi DKI Jakarta,
          Kode Pos: 12190</div>
      </v-col>
    </v-row>
</template>

<script>
  export default {
    name: 'Address',
  }
</script>
